
import { ref, defineComponent, onMounted, computed } from "vue";
import QuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import Avatar from "vue-avatar-component";
import { useStore } from "vuex";

export default defineComponent({
  name: "topbar",
  components: {
    KTNotificationsMenu,
    KTUserMenu,
    Avatar,QuickLinksMenu
  },
  setup() {
    const store = useStore();
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    return { currentUser, store };
  },
});
