
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Avatar from "vue-avatar-component";
import { Roles } from "@/store/enums/RolesEnums";
import { useConfirm } from "primevue/useconfirm";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default defineComponent({
  name: "kt-user-menu",
  components: { Avatar },
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();
    const confirm = useConfirm();
    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const currentUser = computed(() => {
      return store.getters.currentUser;
    });

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const deleteUser = (event) => {
      confirm.require({
            target: event.currentTarget,
            message: '¿Está seguro de eliminar su cuenta?',
            icon: 'pi pi-info-circle',
            acceptLabel:"Si",
            rejectLabel:"No",
            acceptClass: 'p-button-danger',
            accept: () => {
                 store
                  .dispatch(Actions.DELETE_USER, currentUser.value.email)
                  .then(() => {
                      Swal.fire({
                        text: "Cuenta eliminada exitosamente.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok!",
                        customClass: {
                          confirmButton: "btn fw-bold btn-light-primary",
                        },
                      }).then(function () {
                        router.push({ name: "sign-in" })
                      });
                    })
                  .catch(() => {
                    const [error] = Object.keys(store.getters.getAuthErrors);
                  });
            },
            reject: () => {
                //toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
            }
        });
      
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    return {
      signOut,
      setLang,
      currentLanguage,
      currentUser,
      Roles,deleteUser
    };
  },
});
