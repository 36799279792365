
import { ref, defineComponent, onMounted, computed } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import { Roles } from "@/store/enums/RolesEnums";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { useStore } from "vuex";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const mainConfig = ref<null | unknown>(null);
    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
      mainConfig.value = MainMenuConfig;
    });
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      mainConfig,
      asideMenuIcons,
      version,
      translate,
      currentUser,
    };
  },
});
