const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Home",
        route: { name: "dashboard" },
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      /* {
        heading: "layoutBuilder",
        route: "/builder",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-layers",
      },*/
    ],
  },
  {
    heading: "Menú",
    route: "/crafted",
    pages: [
      {
        heading: "Declarar Siniestro",
        svgIcon: "media/icons/duotune/general/gen011.svg",
        fontIcon: "bi-car",
        route: { name: "accidents" },
      },
      {
        heading: "Agendar Mantención",
        svgIcon: "media/icons/duotune/general/gen014.svg",
        fontIcon: "bi-person",
        route: { name: "agenda" },
      },
      {
        sectionTitle: "Asistencias",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Asistencia en ruta",
            route: { name: "assists" },
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;
