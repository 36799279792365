
import { ref, defineComponent, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n/index";
import { headerMenuIcons } from "@/core/helpers/config";
import { version } from "@/core/helpers/documentation";
import { Roles } from "@/store/enums/RolesEnums";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { useStore } from "vuex";
export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const store = useStore();
    const mainConfig = ref<null | unknown>(null);
    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    onMounted(() => {
      mainConfig.value = MainMenuConfig;
    });
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    return {
      hasActiveChildren,
      headerMenuIcons,
      mainConfig,
      translate,
      version,
    };
  },
});
