
import { defineComponent, ref, onMounted } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTMenu from "@/layout/header/Menu.vue";
import {isMobileDevice} from './../../../src/assets/ts/_utils/DomHelpers'
import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    KTMenu,
  },
  setup() {
    const isMobileStyle = () => {
      return isMobileDevice()?'top:30px':'';
    }
    const toolbarMobile = ref<HTMLElement | null>(null);
    console.log('setup');
    const isMobile = ref(isMobileDevice());

    const moveToolbar = ()=> {
      console.log('toolbar.value ' + toolbarMobile.value);
      if(toolbarMobile.value)
        toolbarMobile.value.style.top = '130px';
    };
    onMounted(() => {
      moveToolbar();
    });

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      isMobile,
      isMobileStyle
    };
  },
});
