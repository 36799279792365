
import { ref, defineComponent, onMounted, computed, inject } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import _ from "lodash";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primevue/api";
//import { getMessaging, getToken, onMessage  } from "firebase/messaging";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";

interface Buscador {
  estado?: number;
  factura?: number;
  empresaId?: number;
  fechaInicio?: Date;
  fechaTermino?: Date;
  proveedorId: number;
}

export default defineComponent({
  name: "kt-notification-list",
  components: {},
  methods: {
    formatDate(value) {
      value = new Date(value);
      return value.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    formatTime(value) {
      return moment(value).format("LT");
    },
    formatCurrency(value) {
      return value.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: "CLP",
      });
    },
  },
  inject: ['messaging'],
  setup() {
    const store = useStore();
    const toast = useToast();
    const notificacion = ref();
    const messaging = inject('messaging') as any;
    onMounted(() => {
      buscarTickets();
      obtenerReservasPorAceptar();
    });
    const reservasNotificadas = computed(() => {
      return store.getters.allReservasPorAceptar;
    });
    /*onMessage(messaging, (payload) => {
      buscarTickets();
      obtenerReservasPorAceptar();
      notificacion.value = payload;
       toast.add({severity: 'info', summary: notificacion.value.data.title, detail: notificacion.value.data.body, life: 3000});
      console.log(`Refrescando luego de notificacion ${payload}`);
    });*/
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const buscarTickets = () => {
        store
          .dispatch(Actions.GET_NOTIFICACIONES, currentUser.value.access_token)
          .then(() => {
             loading.value = false;
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getNotificacionErrors);
          });
    };
    const obtenerReservasPorAceptar = () => {
      store
        .dispatch(Actions.GET_RESERVAS_POR_ACEPTAR, currentUser.value.driverId)
        .then(() => {
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getCuentaErrors);
        });
    };

    const notificaciones = computed(() => {
      return store.getters.allNotificaciones;
    });
    const loading = ref(true);
    return { loading, store, notificaciones, reservasNotificadas };
  },
});
