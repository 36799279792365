
import { ref, defineComponent, onMounted, computed, inject } from "vue";
import _ from "lodash";
import moment from "moment";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
  name: "kt-notification-list",
  components: {},
  methods: {
    formatDate(value) {
      value = new Date(value);
      return value.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    formatTime(value) {
      return moment(value).format("LT");
    },
    formatCurrency(value) {
      return value.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: "CLP",
      });
    },
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      obtenerReservasPorAceptar();
    });
    const reservasNotificadas = computed(() => {
      return store.getters.allReservasPorAceptar;
    });
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const obtenerReservasPorAceptar = () => {
      store
        .dispatch(Actions.GET_RESERVAS_POR_ACEPTAR, currentUser.value.driverId)
        .then(() => {
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getCuentaErrors);
        });
    };
    const loading = ref(true);
    return { loading, store, reservasNotificadas };
  },
});
